// Imports => React
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import {
	ICONS,
	KEYS,
	PERMISSIONS,
	ROUTES,
	THEMES,
	TITLES,
	TYPES,
	VARIANTS,
	VISUALS,
} from '@constants';

// Imports => Utilities
import { AcIsSet, AcIsNull, AcIsEmptyString, AcUUID } from '@utils';

// Imports => Hooks
import { usePermissions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';
import AcAddControlUnitModal from '@molecules/ac-add-control-unit-modal/ac-add-control-unit-modal.web';
import AcAddHammerModal from '@molecules/ac-add-hammer-modal/ac-add-hammer-modal.web';
import AcAddPowerpackModal from '@molecules/ac-add-powerpack-modal/ac-add-powerpack-modal.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
	MAIN: 'ac-add-equipment-modal',
	CONTENT: 'ac-add-equipment-modal__content',
};

const AcAddEquipmentModal = ({
	store: { ui, hammers, control_units, powerpacks, jeanlutz, companies },
	callback,
}) => {
	const navigate = useNavigate();
	const { can, cannot } = usePermissions();

	const [eqtype, setEqType] = useState(null);

	useEffect(() => {
		if (can(PERMISSIONS.EQUIPMENT.MANAGE_DETAILS))
			companies.list({ per_page: 999, options: true });
	}, []);

	useEffect(() => {
		if (AcIsSet(eqtype)) renderAddModal();
	}, [eqtype]);

	const handleSetEQType = (event, type) => {
		if (!AcIsSet(type)) return;

		setEqType(type);
	};

	const handleCancel = async (event) => {
		if (event && event.preventDefault) event.preventDefault();
		await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
	};

	const renderAddModal = useCallback(() => {
		if (!AcIsSet(eqtype)) return null;

		let result = null;

		switch (eqtype) {
			case KEYS.CONTROL_UNITS:
				result = <AcAddControlUnitModal submit={control_units.store} />;
				ui.setValue(KEYS.MODAL, KEYS.TITLE, TITLES.NEW_CONTROL_UNIT);
				break;

			case KEYS.HAMMERS:
				result = <AcAddHammerModal submit={hammers.store} />;
				ui.setValue(KEYS.MODAL, KEYS.TITLE, TITLES.NEW_HAMMER);
				break;

			case KEYS.POWERPACKS:
				result = <AcAddPowerpackModal submit={powerpacks.store} />;
				ui.setValue(KEYS.MODAL, KEYS.TITLE, TITLES.NEW_POWERPACK);
				break;

			default:
		}

		const modal = Object.assign({}, ui.current_modal);
		ui.set(KEYS.MODAL, {
			...modal,
			body: result,
			id: AcUUID(),
			callback: async () => {
				await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
			},
		});

		return result;
	}, [eqtype]);

	const getCTAButtonOptions = useCallback((key) => {
		return {
			title: 'Add equipment',
			callback: (event) => handleSetEQType(event, key),
			className: 'ac-button--pitch-cta',
		};
	}, []);

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<AcContainer fluid>
					<AcRow>
						<AcColumn xs={12} className={'h-margin-bottom-20'}>
							<AcRichContent
								content={'<p>What equipment would you like to add?</p>'}
							/>
						</AcColumn>
					</AcRow>

					<AcRow>
						<AcColumn xs={12} sm={4}>
							<AcButton {...getCTAButtonOptions(KEYS.CONTROL_UNITS)}>
								<AcIcon icon={ICONS.CONTROL_UNIT} />
								<span>Control unit</span>
							</AcButton>
						</AcColumn>

						<AcColumn xs={12} sm={4}>
							<AcButton {...getCTAButtonOptions(KEYS.HAMMERS)}>
								<AcIcon icon={ICONS.HAMMER} />
								<span>Hammer</span>
							</AcButton>
						</AcColumn>

						<AcColumn xs={12} sm={4}>
							<AcButton {...getCTAButtonOptions(KEYS.POWERPACKS)}>
								<AcIcon icon={ICONS.POWERPACK} />
								<span>Powerpack</span>
							</AcButton>
						</AcColumn>
					</AcRow>
				</AcContainer>
			</div>
		</div>
	);
};

export default withStore(observer(AcAddEquipmentModal));
