// Imports => React
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { Fade } from 'react-awesome-reveal';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Constants
import {
  ICONS,
  KEYS,
  PERMISSIONS,
  ROLES,
  ROUTES,
  SIZES,
  THEMES,
  TITLES,
  TYPES,
  VARIANTS,
} from '@constants';

// Imports => Utilities
import {
  AcIsSet,
  AcSortBy,
  AcFormatRole,
  AcGenerateBasicPassword,
  AcGenerateAdvancedPassword,
} from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcHeading = loadable(() => import('@atoms/ac-heading/ac-heading.web'));
const AcRichContent = loadable(() =>
  import('@atoms/ac-rich-content/ac-rich-content.web')
);
const AcTextInput = loadable(() =>
  import('@atoms/ac-text-input/ac-text-input.web')
);
const AcSelectBox = loadable(() =>
  import('@atoms/ac-select-box/ac-select-box.web')
);
const AcCheckbox = loadable(() => import('@atoms/ac-checkbox/ac-checkbox.web'));
const AcButton = loadable(() => import('@atoms/ac-button/ac-button.web'));
const AcIcon = loadable(() => import('@atoms/ac-icon/ac-icon.web'));
const AcRipple = loadable(() => import('@atoms/ac-ripple/ac-ripple.web'));
const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader.web'));

const _CLASSES = {
  MAIN: 'ac-add-user-modal',
  CONTENT: 'ac-add-user-modal__content',
};

const AcAddUserModal = ({
  store: { ui, users, companies },
  withCompany = false,
  currentCompany = undefined,
  multistep = {
    roles: true,
    companies: false,
  },
  submit,
  cancel,
  callback,
}) => {
  const navigate = useNavigate();
  const { can, cannot } = usePermissions();

  let raw_fields = {
    name: '',
    email: '',
    phone: '',
    type: 'client',
    companies: currentCompany ? currentCompany : '',
  };
  let raw_errors = {
    name: undefined,
    email: undefined,
    phone: undefined,
    companies: currentCompany ? null : undefined,
    type: null,
  };

  if (cannot(PERMISSIONS.USER.MANAGE_ALL)) {
    delete raw_fields.type;
    delete raw_errors.type;
  }

  const [fields, setFields] = useState(raw_fields);
  const [errors, setErrors] = useState(raw_errors);

  const [newUserObject, setNewUserObject] = useState({});

  const { hasErrors, handleInputChange, handleInputValidation } =
    useFormActions({
      fields,
      setFields,
      errors,
      setErrors,
    });

  const resetNewUserObject = () => {
    setNewUserObject({});
  };

  const handleCancel = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
    if (cancel) cancel();
  };

  const handleSubmit = async (event) => {
    if (event && event.preventDefault) event.preventDefault();

    let blocked = false;

    if (can(PERMISSIONS.USER.MANAGE_ALL)) {
      const result = await users.verify_by_email(fields);

      console.group('verify_by_email');
      console.log('email', fields.email);
      console.log('fields', fields);
      console.log('result', result);
      console.groupEnd();

      if (result?.exists) {
        displayUserAlreadyExistsModal(event, fields, result);

        blocked = true;
      }
    }

    if (!blocked) {
      handleAssignValuesToNewUser();
    }
  };

  useEffect(() => {
    if (
      !companies.current_companies_list.length &&
      can(PERMISSIONS.USER.MANAGE_ALL)
    ) {
      companies.list({ options: true });
    }
  }, []);

  useEffect(() => {
    let conf = ui.current_modal?.progress;
    if (fields.type === 'iqip') {
      ui.setValue(KEYS.MODAL, 'progress', { ...conf, total_steps: 2 });
    } else if (fields.type === 'client') {
      ui.setValue(KEYS.MODAL, 'progress', {
        ...conf,
        total_steps: 2,
      });
    }
  }, [fields?.type]);

  const handleAssignValuesToNewUser = useCallback(async () => {
    return new Promise(async (resolve) => {
      let obj = newUserObject;

      obj.name = fields.name;
      obj.email = fields.email;
      obj.phone = fields.phone;
      obj.companies = [fields.companies];

      if (fields.type) obj.type = fields.type;

      await setNewUserObject(obj);

      if (multistep.roles) {
        displayAssignRolesToUserModal();
      } else {
        await users.store(newUserObject).then((response) => {
          navigate(ROUTES.USER_DETAIL.path.replace(':id', response.id));
        });
      }

      resolve();
    });
  }, [fields, multistep]);

  const handleAssignRolesToNewUser = async (selection, collection) => {
    return new Promise(async (resolve) => {
      let obj = newUserObject;

      let selectedRoles = [];

      selection.forEach((roleID) => {
        const found = collection.find((a) => a.id === roleID);

        if (found) selectedRoles.push(found.name);
      });

      obj.roles = selectedRoles;

      await setNewUserObject(obj);

      await users.store(newUserObject).then((response) => {
        navigate(ROUTES.USER_DETAIL.path.replace(':id', response.id));
      });

      resolve();
    });
  };

  const handleAssignCompaniesToNewUser = async (selection) => {
    return new Promise(async (resolve) => {
      let obj = newUserObject;

      obj.companies = selection;

      await setNewUserObject(obj);

      displayAssignRolesToUserModal();

      resolve();
    });
  };

  const displayUserAlreadyExistsModal = useCallback(
    async (event, fields, user) => {
      if (event && event.preventDefault) event.preventDefault();
      if (event && event.stopPropagation) event.stopPropagation();

      if (cannot(PERMISSIONS.USER.MANAGE_ALL)) return;

      const selectedCompany = companies.current_companies_list.find(
        (obj) => obj.id === fields.companies
      );
      const currentCompanies = user?.companies?.join(', ');

      let content = `<p>An user with email address <strong>${
        fields.email
      }</strong> already exists within the ${
        user?.companies?.length > 1 ? 'companies' : 'company'
      } <strong>${currentCompanies}</strong>.</p><p class="h-margin-top-15">Would you like to add this user without a company?</p>`;

      if (AcIsSet(selectedCompany?.name)) {
        content = `<p>An user with email address <strong>${
          fields.email
        }</strong> already exists within the ${
          user?.companies?.length > 1 ? 'companies' : 'company'
        } <strong>${currentCompanies}</strong>.</p><p class="h-margin-top-15">Would you like to add this user to company <strong>${
          selectedCompany.name
        }</strong>?</p>`;
      }

      ui.confirm({
        instance: users,
        title: TITLES.USER_EXISTS,
        content,
        confirm: {
          label: 'Yes, add to company',
          callback: () => {
            return new Promise((resolve, reject) => {
              users
                .add_user_to_company(user?.user_id, {
                  companies: fields.companies,
                })
                .then(() => {
                  resolve();
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
      });
    },
    [companies, fields]
  );

  const displayAssignCompaniesToUserModal = useCallback(async () => {
    if (cannot(PERMISSIONS.USER.MANAGE_ALL)) return;

    await companies.list({ options: true }, false);
    const collection = companies.current_companies_list;

    ui.multi_select({
      instance: users,
      progress: {
        total_steps: 2,
        current_step: 1,
      },
      collection,
      multiple: true,
      initialSelection: [],
      alwaysShowSearch: true,
      title: TITLES.ASSIGN_COMPANIES_TO_USER,
      wide: false,
      introduction: '<p>Select 1 or more companies to assign this user to.</p>',
      callback: () => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
      verify_errors: false,
      confirm: {
        label: can(PERMISSIONS.USER.MANAGE_ALL) ? 'Continue' : 'Save user',
        callback: (selection) => {
          return handleAssignCompaniesToNewUser(selection);
        },
      },
      cancel: {
        label: 'Cancel',
        callback: () => {
          resetNewUserObject();
        },
      },
    });
  }, [companies, newUserObject]);

  const displayAssignRolesToUserModal = useCallback(async () => {
    if (cannot(PERMISSIONS.USER.MANAGE)) return;

    const all_roles = await users.get_roles({ companyRoles: 1 });

    const collection = all_roles.map((role) => ({
      id: role.id,
      name: AcFormatRole(role.name),
    }));

    ui.multi_select({
      instance: users,
      progress: {
        total_steps: can(PERMISSIONS.USER.MANAGE_ALL) ? 3 : 2,
        current_step: can(PERMISSIONS.USER.MANAGE_ALL) ? 2 : 1,
      },
      collection,
      multiple: true,
      initialSelection: [],
      alwaysShowSearch: true,
      title: TITLES.ASSIGN_ROLES_TO_USER,
      wide: false,
      introduction: '<p>Select 1 or more roles to assign to this user.</p>',
      callback: () => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
      },
      verify_errors: false,
      confirm: {
        label: 'Save user',
        callback: (selection) => {
          return handleAssignRolesToNewUser(selection, all_roles);
        },
      },
      cancel: {
        label: 'Cancel',
        callback: () => {
          resetNewUserObject();
        },
      },
    });
  }, [companies, newUserObject]);

  const getContentClassNames = useMemo(() => {
    return clsx([_CLASSES.CONTENT]);
  }, []);

  const getStyleClassNames = useMemo(() => {
    return clsx([_CLASSES.MAIN]);
  }, []);

  const getCompanySelectOptions = useMemo(() => {
    const { current_companies_list } = companies;
    let options = [];

    if (current_companies_list) {
      const len = current_companies_list.length;
      let n = 0;

      for (n; n < len; n++) {
        const item = current_companies_list[n];

        const object = {
          name: item.name,
          value: item.id,
        };

        options.push(object);
      }
    }

    return {
      type: TYPES.TEXT,
      label: 'Company',
      name: 'companies',
      placeholder: 'Select a company',
      value: fields.companies,
      callback: handleInputChange,
      validation: handleInputValidation,
      maxOptions: 6,
      options,
    };
  }, [companies.current_companies_list, fields, fields.companies]);

  const getNameInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      label: 'Name',
      name: 'name',
      value: fields.name,
      callback: handleInputChange,
      validation: handleInputValidation,
      focus: true,
    };
  }, [fields, fields.name]);

  const getEmailInputOptions = useMemo(() => {
    return {
      type: TYPES.EMAIL,
      label: 'Email address',
      placeholder: 'name@domain.com',
      name: 'email',
      value: fields.email,
      callback: handleInputChange,
      validation: handleInputValidation,
    };
  }, [fields, fields.email]);

  const getPhoneInputOptions = useMemo(() => {
    return {
      type: TYPES.PHONE,
      label: 'Phone number',
      placeholder: 'Phone number',
      name: 'phone',
      required: false,
      value: fields.phone,
      callback: handleInputChange,
      validation: handleInputValidation,
    };
  }, [fields, fields.phone]);

  const getIQIPUserRadioButtonOptions = useMemo(() => {
    return {
      type: TYPES.RADIO,
      name: 'type',
      value: 'iqip',
      callback: handleInputChange,
      checked: fields.type === 'iqip',
    };
  }, [fields, fields.type]);

  const getClientUserRadioButtonOptions = useMemo(() => {
    return {
      type: TYPES.RADIO,
      name: 'type',
      value: 'client',
      callback: handleInputChange,
      checked: fields.type === 'client',
    };
  }, [fields, fields.type]);

  const getCancelButtonOptions = useMemo(() => {
    return {
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TEXT,
      title: 'Cancel',
      callback: handleCancel,
    };
  });

  const getSubmitButtonOptions = useMemo(() => {
    return {
      type: TYPES.SUBMIT,
      theme: THEMES.ALPHA,
      disabled: errors && hasErrors,
      title: 'Save',
      callback: handleSubmit,
    };
  }, [fields, errors, hasErrors]);

  return (
    <div className={getStyleClassNames}>
      <div className={getContentClassNames}>
        <form method={'post'} onSubmit={handleSubmit}>
          <AcContainer fluid>
            {withCompany && (
              <AcRow>
                <AcColumn>
                  <AcSelectBox {...getCompanySelectOptions} />
                </AcColumn>
              </AcRow>
            )}
            <AcRow>
              <AcColumn>
                <AcTextInput {...getNameInputOptions} />
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>
                <AcTextInput {...getEmailInputOptions} />
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn>
                <AcTextInput {...getPhoneInputOptions} />
              </AcColumn>
            </AcRow>

            <AcRow className={'h-margin-top-20'}>
              <AcColumn
                xxs={12}
                xs={7}
                sm={6}
                className={'h-text--align-left h-flex-v-align-center'}
              >
                <AcButton {...getCancelButtonOptions}>
                  <span>Cancel</span>
                </AcButton>
              </AcColumn>

              <AcColumn
                xxs={12}
                xs={5}
                sm={6}
                className={'h-text--align-right'}
              >
                <AcButton {...getSubmitButtonOptions}>
                  <span>{multistep ? 'Continue' : 'Save'}</span>
                </AcButton>
              </AcColumn>
            </AcRow>
          </AcContainer>
        </form>
      </div>

      {users.is_busy && <AcLoader loading={true} cover />}
    </div>
  );
};

export default withStore(observer(AcAddUserModal));
