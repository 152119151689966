// Imports => React
import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import {
  DATETIME_FORMATS,
  KEYS,
  PERMISSIONS,
  ROUTES,
  THEMES,
  TYPES,
  VARIANTS,
} from '@constants';

// Imports => Utilities
import { AcFormatDate } from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcDatepickerInput from '@atoms/ac-datepicker-input/ac-datepicker-input.web';
import AcSelectBox from '@atoms/ac-select-box/ac-select-box.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
  MAIN: 'ac-add-project-modal',
  CONTENT: 'ac-add-project-modal__content',
};

const AcAddProjectModal = ({
  store: { ui, projects, companies },
  submit,
  callback,
}) => {
  const { can, cannot } = usePermissions();
  const navigate = useNavigate();

  const start = new Date();
  const end = new Date();
  const date_start = start;
  const date_end = end.setMonth(end.getMonth() + 1);

  let raw_fields = {
    name: '',
    company_id: '',
    customer_name: '',
    customer_project_uid: '',
    date_start,
    date_end,
    lat: 51.8218586,
    lng: 4.7595114,
  };
  let raw_errors = {
    name: undefined,
    company_id: undefined,
    customer_name: null,
    customer_project_uid: undefined,
    date_start: null,
    date_end: null,
    lat: null,
    lng: null,
  };

  if (cannot(PERMISSIONS.PROJECT.CREATE_ALL)) {
    delete raw_fields.company_id;
    delete raw_errors.company_id;

    delete raw_fields.customer_project_uid;
    delete raw_errors.customer_project_uid;

    delete raw_fields.customer_name;
    delete raw_errors.customer_name;
  }

  const [fields, setFields] = useState(raw_fields);
  const [errors, setErrors] = useState(raw_errors);

  const { hasErrors, handleInputChange, handleInputValidation } =
    useFormActions({
      fields,
      setFields,
      errors,
      setErrors,
    });

  useEffect(() => {
    if (can(PERMISSIONS.PROJECT.CREATE_ALL)) companies.list({ options: true });
  }, []);

  const handleCancel = async (event) => {
    if (event && event.preventDefault) event.preventDefault();
    await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
  };

  const handleSubmit = (event) => {
    if (event && event.preventDefault) event.preventDefault();
    if (event && event.persist) event.persist();

    if (submit) {
      const date_start = AcFormatDate(
        fields.date_start,
        null,
        DATETIME_FORMATS.RAW_DATE
      );
      const date_end = AcFormatDate(
        fields.date_end,
        null,
        DATETIME_FORMATS.RAW_DATE
      );

      const object = {
        ...fields,
        date_start,
        date_end,
        location: {
          lat: fields.lat,
          lng: fields.lng,
        },
      };

      submit(object).then((response) => {
        ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
        if (navigate) {
          navigate(ROUTES.PROJECT_DETAIL.path.replace(':id', response.id));
        }
      });
    }
  };

  const getContentClassNames = useMemo(() => {
    return clsx([_CLASSES.CONTENT]);
  }, []);

  const getStyleClassNames = useMemo(() => {
    return clsx([_CLASSES.MAIN]);
  }, []);

  const getProjectNameInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      label: 'Project name',
      name: 'name',
      value: fields.name,
      callback: handleInputChange,
      validation: handleInputValidation,
      required: true,
      focus: true,
    };
  }, [fields, fields.name]);

  const getCustomerNumberInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      label: 'Customer Project UID',
      name: 'customer_project_uid',
      value: fields.customer_project_uid,
      callback: handleInputChange,
      validation: handleInputValidation,
    };
  }, [fields, fields.customer_project_uid]);

  const getCustomerNameInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      label: 'Customer name',
      name: 'customer_name',
      value: fields.customer_name,
      callback: handleInputChange,
      validation: handleInputValidation,
    };
  }, [fields, fields.customer_name]);

  const getCompanySelectOptions = useMemo(() => {
    const { current_companies_list } = companies;
    let options = [];

    if (current_companies_list) {
      const len = current_companies_list.length;
      let n = 0;

      for (n; n < len; n++) {
        const item = current_companies_list[n];

        const object = {
          name: item.name,
          value: item.id,
        };

        options.push(object);
      }
    }

    return {
      type: TYPES.TEXT,
      label: 'Company',
      name: 'company_id',
      placeholder: 'Select a company',
      value: fields.company_id,
      callback: handleInputChange,
      validation: handleInputValidation,
      maxOptions: 6,
      options,
    };
  }, [companies.current_companies_list, fields, fields.company_id]);

  const getLatitudeInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      label: 'Location latitude',
      name: 'lat',
      value: fields.lat,
      callback: handleInputChange,
      validation: handleInputValidation,
      required: true,
    };
  }, [fields, fields.lat]);

  const getLongitudeInputOptions = useMemo(() => {
    return {
      type: TYPES.TEXT,
      label: 'Location longitude',
      name: 'lng',
      value: fields.lng,
      callback: handleInputChange,
      validation: handleInputValidation,
      required: true,
    };
  }, [fields, fields.lng]);

  const getStartDateInputOptions = useMemo(() => {
    const end = new Date(fields.date_end);
    const start = new Date(fields.date_start);

    return {
      type: TYPES.DATE,
      label: 'Start date',
      placeholder: 'dd-mm-yyyy',
      name: 'date_start',
      value: start,
      start: start,
      end: end,
      range: 'start',
      callback: handleInputChange,
      validation: handleInputValidation,
    };
  }, [fields, fields.date_start, fields.date_end]);

  const getEndDateInputOptions = useMemo(() => {
    const end = new Date(fields.date_end);
    const start = new Date(fields.date_start);

    return {
      type: TYPES.DATE,
      label: 'End date',
      placeholder: 'dd-mm-yyyy',
      name: 'date_end',
      value: end,
      min: start,
      start: start,
      end: end,
      range: 'end',
      callback: handleInputChange,
      validation: handleInputValidation,
    };
  }, [fields, fields.date_end, fields.date_start]);

  const getCancelButtonOptions = useMemo(() => {
    return {
      type: TYPES.BUTTON,
      theme: THEMES.OMEGA,
      variant: VARIANTS.TEXT,
      title: 'Cancel',
      callback: handleCancel,
    };
  });

  const getSubmitButtonOptions = useMemo(() => {
    return {
      type: TYPES.SUBMIT,
      theme: THEMES.ALPHA,
      disabled: hasErrors,
      title: 'Save',
      callback: handleSubmit,
    };
  }, [fields, errors, hasErrors]);

  return (
    <div className={getStyleClassNames}>
      <div className={getContentClassNames}>
        <form method={'post'} onSubmit={handleSubmit}>
          <AcContainer fluid>
            <AcRow>
              <AcColumn>
                <AcTextInput {...getProjectNameInputOptions} />
              </AcColumn>
            </AcRow>

            <AcCheckPermissions allowed={PERMISSIONS.PROJECT.CREATE_ALL}>
              <AcRow>
                <AcColumn>
                  <AcTextInput {...getCustomerNumberInputOptions} />
                </AcColumn>
              </AcRow>
            </AcCheckPermissions>

            <AcCheckPermissions allowed={PERMISSIONS.PROJECT.CREATE_ALL}>
              <AcRow>
                <AcColumn>
                  <AcTextInput {...getCustomerNameInputOptions} />
                </AcColumn>
              </AcRow>
            </AcCheckPermissions>

            <AcCheckPermissions allowed={PERMISSIONS.PROJECT.CREATE_ALL}>
              <AcRow>
                <AcColumn>
                  <AcSelectBox {...getCompanySelectOptions} />
                </AcColumn>
              </AcRow>
            </AcCheckPermissions>

            <AcRow>
              <AcColumn xs={12} sm={6}>
                <AcTextInput {...getLatitudeInputOptions} />
              </AcColumn>

              <AcColumn xs={12} sm={6}>
                <AcTextInput {...getLongitudeInputOptions} />
              </AcColumn>
            </AcRow>

            <AcRow>
              <AcColumn xs={12} sm={6}>
                <AcDatepickerInput {...getStartDateInputOptions} />
              </AcColumn>

              <AcColumn xs={12} sm={6}>
                <AcDatepickerInput {...getEndDateInputOptions} />
              </AcColumn>
            </AcRow>

            <AcRow className={'h-margin-top-20'}>
              <AcColumn
                xxs={12}
                xs={7}
                sm={6}
                className={'h-text--align-left h-flex-v-align-center'}
              >
                <AcButton {...getCancelButtonOptions}>
                  <span>Cancel</span>
                </AcButton>
              </AcColumn>

              <AcColumn
                xxs={12}
                xs={5}
                sm={6}
                className={'h-text--align-right'}
              >
                <AcButton {...getSubmitButtonOptions}>
                  <span>Save</span>
                </AcButton>
              </AcColumn>
            </AcRow>
          </AcContainer>
        </form>
      </div>

      {(projects.is_busy || companies.is_loading) && (
        <AcLoader loading={true} cover />
      )}
    </div>
  );
};

export default withStore(observer(AcAddProjectModal));
