// Imports => React
import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import {
	KEYS,
	PERMISSIONS,
	ROUTES,
	THEMES,
	TYPES,
	VARIANTS,
	VISUALS,
} from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcSelectBox from '@atoms/ac-select-box/ac-select-box.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
	MAIN: 'ac-add-control-unit-modal',
	CONTENT: 'ac-add-control-unit-modal__content',
};

const AcAddControlUnitModal = ({
	store: { ui, control_units, control_unit_types, companies, jeanlutz },
	submit,
	callback,
}) => {
	const navigate = useNavigate();
	const { can, cannot } = usePermissions();

	const getInitialCompanyID = () => {
		if (!AcIsSet(companies.current_companies_list)) return 1;

		const { current_companies_list } = companies;
		const collection = current_companies_list;
		const len = current_companies_list.length;
		let n = 0;
		let result = null;

		for (n; n < len; n++) {
			const item = current_companies_list[n];

			let { name } = item;
			name = name.toUpperCase();

			if (name === KEYS.IHC_IQIP_RENTAL) {
				result = item.id;
				break;
			}
		}

		return result || 1;
	};

	let raw_fields = {
		object_no: '',
		equipment_type_id: '',
		external_key: '',
		company_id: getInitialCompanyID(),
	};
	let raw_errors = {
		object_no: undefined,
		equipment_type_id: undefined,
		external_key: undefined,
		company_id: null,
	};

	if (cannot(PERMISSIONS.EQUIPMENT.MANAGE_DETAILS)) {
		delete raw_fields.company_id;
		delete raw_errors.company_id;
	}

	const [fields, setFields] = useState(raw_fields);
	const [errors, setErrors] = useState(raw_errors);

	const { hasErrors, handleInputChange, handleInputValidation } =
		useFormActions({
			fields,
			setFields,
			errors,
			setErrors,
		});

	useEffect(() => {
		control_unit_types.list({ options: true });
	}, []);

	useEffect(() => {
		handleInputChange(null, 'external_key', '', TYPES.TEXT);
		handleInputValidation('external_key', '', true, TYPES.TEXT);
	}, [fields.equipment_type_id]);

	const getSerialNumberOptionsByControlUnitType = (selected) => {
		if (AcIsSet(selected) && AcIsSet(selected.series)) {
			jeanlutz.list_control_units({ series: selected.series, options: true });
		}
	};

	const handleCancel = async (event) => {
		if (event && event.preventDefault) event.preventDefault();
		await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
	};

	const handleSubmit = (event) => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.persist) event.persist();

		if (submit)
			submit(fields).then((response) => {
				ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
				if (navigate) {
					navigate(ROUTES.CONTROL_UNIT_DETAIL.path.replace(':id', response.id));
				}
			});
	};

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	const getObjectNumberInputOptions = useMemo(() => {
		return {
			type: TYPES.TEXT,
			label: 'Object number',
			name: 'object_no',
			value: fields.object_no,
			callback: handleInputChange,
			validation: handleInputValidation,
			focus: true,
		};
	}, [
		fields,
		fields.object_no,
		fields.equipment_type_id,
		fields.external_key,
		fields.company_id,
	]);

	const getEquipmentTypeSelectOptions = useMemo(() => {
		const { current_control_unit_types_list } = control_unit_types;
		let options = [];

		if (current_control_unit_types_list) {
			const len = current_control_unit_types_list.length;
			let n = 0;

			for (n; n < len; n++) {
				const item = current_control_unit_types_list[n];

				const object = {
					name: item.name,
					value: item.id,
					series: item.series,
				};

				options.push(object);
			}
		}

		return {
			type: TYPES.TEXT,
			label: 'Object type',
			name: 'equipment_type_id',
			placeholder: 'Select a control unit type',
			value: fields.equipment_type_id,
			callback: (event, name, value, type) => {
				handleInputChange(event, name, value, type);

				const selected = options.find((r) => r.value === value);
				if (AcIsSet(selected)) {
					getSerialNumberOptionsByControlUnitType(selected);
				}
			},
			validation: handleInputValidation,
			options,
		};
	}, [
		control_unit_types,
		control_unit_types.current_control_unit_types_list,
		fields,
		fields.object_no,
		fields.equipment_type_id,
		fields.external_key,
		fields.company_id,
	]);

	const getSerialNumberSelectOptions = useMemo(() => {
		const { current_jean_lutz_control_units } = jeanlutz;
		let options = [];

		if (current_jean_lutz_control_units) {
			const len = current_jean_lutz_control_units.length;
			let n = 0;

			for (n; n < len; n++) {
				const item = current_jean_lutz_control_units[n];

				const object = {
					name: `${item.value}`,
					value: item.value,
				};

				options.push(object);
			}
		}

		let isDisabled = false;

		if (
			jeanlutz.is_loading ||
			!AcIsSet(fields.equipment_type_id) ||
			fields.equipment_type_id === '' ||
			!AcIsSet(current_jean_lutz_control_units) ||
			!current_jean_lutz_control_units.length
		) {
			isDisabled = true;
		}

		return {
			type: TYPES.TEXT,
			label: 'Serialnumber',
			name: 'external_key',
			placeholder: 'Select a serialnumber.',
			value: fields.external_key,
			callback: handleInputChange,
			validation: handleInputValidation,
			disabled: isDisabled,
			options,
		};
	}, [
		jeanlutz,
		jeanlutz.current_jean_lutz_control_units,
		jeanlutz.is_loading,
		fields,
		fields.object_no,
		fields.equipment_type_id,
		fields.external_key,
		fields.company_id,
	]);

	const getCompanySelectOptions = useMemo(() => {
		const { current_companies_list } = companies;
		let options = [
			{
				name: '- Unassigned',
				value: null,
			},
		];

		if (current_companies_list) {
			const len = current_companies_list.length;
			let n = 0;

			for (n; n < len; n++) {
				const item = current_companies_list[n];

				const object = {
					name: item.name,
					value: item.id,
				};

				options.push(object);
			}
		}

		return {
			type: TYPES.TEXT,
			label: 'Company',
			name: 'company_id',
			placeholder: 'Select a company',
			value: fields.company_id,
			callback: handleInputChange,
			validation: handleInputValidation,
			maxOptions: 6,
			options,
		};
	}, [
		companies,
		companies.current_companies_list,
		fields,
		fields.object_no,
		fields.equipment_type_id,
		fields.external_key,
		fields.company_id,
	]);

	const getCancelButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.OMEGA,
			variant: VARIANTS.TEXT,
			title: 'Cancel',
			callback: handleCancel,
		};
	});

	const getSubmitButtonOptions = useMemo(() => {
		return {
			type: TYPES.SUBMIT,
			theme: THEMES.ALPHA,
			disabled: hasErrors,
			title: 'Save',
			callback: handleSubmit,
		};
	}, [
		fields.object_no,
		fields.equipment_type_id,
		fields.external_key,
		fields.company_id,
		errors,
		hasErrors,
	]);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<form method={'post'} onSubmit={handleSubmit}>
					<AcContainer fluid>
						<AcRow>
							<AcColumn>
								<AcTextInput {...getObjectNumberInputOptions} />
							</AcColumn>
						</AcRow>

						<AcRow>
							<AcColumn>
								<AcSelectBox {...getEquipmentTypeSelectOptions} />
							</AcColumn>
						</AcRow>

						<AcRow>
							<AcColumn>
								<AcSelectBox {...getSerialNumberSelectOptions} />
							</AcColumn>
						</AcRow>

						<AcCheckPermissions allowed={PERMISSIONS.EQUIPMENT.MANAGE_DETAILS}>
							<AcRow>
								<AcColumn>
									<AcSelectBox {...getCompanySelectOptions} />
								</AcColumn>
							</AcRow>
						</AcCheckPermissions>

						<AcRow className={'h-margin-top-20'}>
							<AcColumn
								xxs={12}
								xs={7}
								sm={6}
								className={'h-text--align-left h-flex-v-align-center'}
							>
								<AcButton {...getCancelButtonOptions}>
									<span>Cancel</span>
								</AcButton>
							</AcColumn>

							<AcColumn
								xxs={12}
								xs={5}
								sm={6}
								className={'h-text--align-right'}
							>
								<AcButton {...getSubmitButtonOptions}>
									<span>Save</span>
								</AcButton>
							</AcColumn>
						</AcRow>
					</AcContainer>
				</form>
			</div>

			{control_units.is_busy && <AcLoader loading={true} cover />}
		</div>
	);
};

export default withStore(observer(AcAddControlUnitModal));
